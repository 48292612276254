
.goods-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .content-body {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            padding-bottom: 15px;
            .el-scrollbar__view {
                height: 100%;
            }
        }
    }
    .goods-info {
        flex: 4;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        overflow: hidden;
        .g-info-title {
            display: flex;
            flex-direction: row;
            height: 50px;
            font-size: 16px;
            color: #333;
            line-height: 50px;
            background: #F6F6F6;
            .g-text {
                flex: 1.6;
                margin-left: 39px;
            }
            .g-item {
                flex: 1;
            }
        }

        .g-content-fillin {
            display: flex;
            flex-direction: column;
            .g-content {
                display: flex;
                flex-direction: row;
                height: 70px;
                background: #FBFBFD ;
                border-bottom: 1px solid #EEEEEE;
                .t-ginfo {
                    flex: 1.6;
                    display: flex;
                    flex-direction: row;
                    height: 70px;
                    margin-left: 39px;
                    font-size: 16px;
                    color: #333333;
                }
                .t-item {
                    flex: 1;
                    height: 70px;
                    font-size: 16px;
                    color: rgba(51, 51, 51, .8);
                    line-height: 70px;
                }
                .g-title {
                    height: 44px;
                    margin-left: 8px;
                    line-height: 70px;
                    display:-webkit-box;
                    text-overflow:ellipsis;
                    overflow:hidden;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient:vertical;
                    // padding-top: 13px;
                }
                .t-parse {
                    text-align: center;
                }
            }
            .g-fillin {
                display: flex;
                flex-direction: column;
                .item {
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid #EEEEEE;
                }
                .item-text {
                    flex: 1;
                    height: 22px;
                    display: flex;
                    flex-direction: row;
                    margin: 24px 0 24px 63px;
                }
                .item-input {
                    flex: 4.6;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 27px 15px 0;
                }
            }
        }
    }
    .goods-operation {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-top: 140px;
    }
}
